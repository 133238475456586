// blog data

export const Blogs = [
    {
        id:1,
        name:"My DEV RETRO 2022",
        tags:["Programming Journey","Javascript","IwriteCode"],
        date:"16 Dec, 2022",
        imgSrc:"https://jornlarsen.hashnode.dev/_next/image?url=https%3A%2F%2Fcdn.hashnode.com%2Fres%2Fhashnode%2Fimage%2Fupload%2Fv1671541414778%2FnMDm1VHJV.jpeg%3Fw%3D1600%26h%3D840%26fit%3Dcrop%26crop%3Dentropy%26auto%3Dcompress%2Cformat%26format%3Dwebp&w=3840&q=75",
        link:"https://jornlarsen.hashnode.dev/how-i-got-to-where-i-am-today-where-it-all-started-devretro2022"
    }
] 